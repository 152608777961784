<template>
  <v-expansion-panels class="article-list" accordion>
    <v-expansion-panel
      class="rounded mt-3"
      v-for="item in articleList"
      :key="item.id"
      :aria-controls="'panel-content-' + item.id"
      role="button"
    >
      <v-expansion-panel-header
        :aria-controls="'panel-content-' + item.id"
        role="button"
        :aria-expanded="panel.includes(item.id) ? 'true' : 'false'"
      >
        <p>
          <strong>{{ item.title }}</strong>
        </p>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div v-if="item.content" v-html="item.content" class="is-content"></div>
        <div
          v-else
          class="is-content-abstract"
          v-html="item.contentAbstract"
        ></div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<style scoped>
.v-expansion-panel-header {
  padding: 16px 28px;
  font-size: 16px;
}
.v-expansion-panel-content__wrap {
  padding: 0 28px 16px;
}
</style>
<script>
export default {
  name: "ArticleAccordionList",
  data() {
    return { panel: [] };
  },
  props: {
    articleList: {
      type: Array,
      required: true
    }
  }
};
</script>
