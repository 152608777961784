<template>
  <v-row class="article-class-grid">
    <v-col
      cols="12"
      sm="6"
      md="4"
      lg="3"
      xl="2"
      v-for="item in articleClasses"
      :key="item.id"
    >
      <v-card
        elevation="3"
        class="article-class-card"
        :to="{
          query: { articleClassId: item.id }
        }"
      >
        <img
          v-if="item.iconSource"
          :src="item.iconSource"
          :alt="`Icona servizio ${item.name}`"
        />
        <div>
          <v-card-title
            ><strong v-html="item.description"></strong
          ></v-card-title>
          <!-- <v-card-subtitle v-html="item.description"></v-card-subtitle> -->
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<style lang="scss">
.article-class-card {
  min-height: 100px;
  height: 100%;
  border-radius: 8px;
  &.router-link-exact-active {
    border-bottom: 4px solid red;
  }
  .v-card__subtitle {
    line-height: 1.3em;
    padding-top: 6px;
  }
  .v-card__title {
    font-size: 1.05rem;
    line-height: 1.2em;
  }
  img.size-full {
    width: 100%;
  }
  .v-expansion-panel-header {
    padding: 16px 28px;
    font-size: 16px;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 28px 16px;
  }
}
</style>
<script>
export default {
  name: "ArticleClassGridList",
  props: {
    articleClasses: {
      type: Array,
      required: true
    }
  }
};
</script>
